import React, { useEffect, useState } from "react";
import "./whyModal.scss";
import {
  Modal,
  ModalDialog,
  ModalHolder,
} from "../../components/condo-modal/CondoModalStyled";
import {
  showBodyOverflow,hideBodyOverflow,
  REACT_APP_S3_CLOUDFLARE_IMAGE_URL
} from "../../constants/constant";
import "react-toastify/dist/ReactToastify.css";
import { SectionHeading } from "../../components/styledComponent";
import PopNotification from "../../components/generic/PopNotification";
import Button from "../../components/generic/button";
import useKeycloakFeatures from "../../keycloak/useKeycloakFeatures";
export const WhyModalPresentation = ({ hide }) => {
  const { doSignUp } = useKeycloakFeatures();
  const closeModal = () => {
    showBodyOverflow();
    hide();
  };
  useEffect(()=>
  {
    hideBodyOverflow();
  },[]);
  return (
    <>
      <Modal className="flex items-center flex-text-center active">
        <ModalDialog className="whyModal">
          <ModalHolder className="relative modalBg">
            <div className="inner-content">
              <SectionHeading>
                Register for Free for the full Virtua experience!
              </SectionHeading>
              <p>Access to more games</p>
              <p>Daily & Weekly quests & challenges</p>
              <p>Special tournaments</p>
              <p>Earn & collect rewards from playing games</p>
              <p>Connect with other players in your personal Gamers Lounge</p>
              <p>Decorate and customize your Gamers Lounge</p>
              <p>Chat with friends</p>
              <Button border0 upper secondary onClick={doSignUp}>
                Register Now
              </Button>
            </div>
            <div className="crossIcon">
              <span onClick={closeModal}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="44"
                  height="44"
                  viewBox="0 0 44 44"
                  fill="none"
                >
                  <path
                    d="M31.2765 15.1433C31.9448 14.4751 31.9448 13.3916 31.2765 12.7234C30.6083 12.0551 29.5248 12.0551 28.8565 12.7234L21.9999 19.58L15.1434 12.7234C14.4751 12.0551 13.3916 12.0551 12.7234 12.7234C12.0551 13.3916 12.0551 14.4751 12.7234 15.1433L19.58 21.9999L12.7234 28.8566C12.0551 29.5248 12.0551 30.6083 12.7234 31.2765C13.3916 31.9448 14.4751 31.9448 15.1433 31.2765L21.9999 24.4199L28.8566 31.2765C29.5248 31.9448 30.6083 31.9448 31.2765 31.2765C31.9448 30.6083 31.9448 29.5248 31.2765 28.8566L24.4199 21.9999L31.2765 15.1433Z"
                    fill="white"
                  />
                </svg>
              </span>
              {/* <img src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}crossicon.svg`} alt="crossIcon" onClick={closeModal} /> */}
            </div>
          </ModalHolder>
        </ModalDialog>
      </Modal>
      <PopNotification />
    </>
  );
};
