import React from "react";
import styled from "styled-components";
import { REACT_APP_S3_CLOUDFLARE_IMAGE_URL } from "../../../constants/constant";
import "./waiting.scss";
import { Para, SectionHeading } from "../../../components/styledComponent";
import Button from "../../../components/generic/button";
import useKeycloakFeatures from "../../../keycloak/useKeycloakFeatures";
const GradientEffect = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
   background: linear-gradient(0deg, rgba(6, 0, 28, 0.00) 49.91%, #06001C 94.15%), linear-gradient(180deg, rgba(0, 0, 0, 0.00) 61.57%, #06001C 100%), linear-gradient(0deg, rgba(6, 0, 28, 0.60) 0%, rgba(6, 0, 28, 0.60) 100%);
  width: 100%;
  z-index: 2;
  height: 100%;
`;

const WaitingSection = () => {
    const {doSignUp}=useKeycloakFeatures();
    return (<>
        <div className="waiting-section">
            <div className="banner">
                {/* <GradientEffect /> */}
                <img
                    className=""
                    src={`${REACT_APP_S3_CLOUDFLARE_IMAGE_URL}waiting.webp`}
                    alt="waiting" />
                <div className="text-holder">
                    <SectionHeading>What are you <span className="d-block">waiting for?</span></SectionHeading>
                    <Para>Join Virtua for FREE to start playing games and collecting rewards now!</Para>
                    <Button border0 upper secondary onClick={doSignUp}>
                        Register Now
                    </Button>
                </div>
            </div>
        </div>
    </>
    );
};

export default WaitingSection;
