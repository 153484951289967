import { useEffect } from "react";
import Container from "../../components/generic/container";
import './contentPages.css';



export const UserGuideline = () => {


    return (
        <>
            <Container>
                <div className="privacy-policy content">
                    <h1 className="main-title">User Guidelines</h1>
                    <div className="top_notice">THE FOLLOWING IS NOT TOLERATED. WE WILL REMOVE CONTENT AND ISSUE A
                        WARNING. SUBSEQUENT MISCONDUCT AND CASES OF EXTREME SEVERITY WILL RESULT IN ACCOUNT DELETION:
                    </div>
                    <ul>
                        <li>Organizing and participating in forms of harassment. Do not engage in or post content
                            encouraging the harassment of individuals or communities. If someone is harassing you be
                            proactive and block them immediately.
                        </li>
                        <li>Circumventing tools that enable users to protect themselves and/or repeatedly targeting a
                            user with unwanted friend requests or messages. Do not create spam accounts for the sake of
                            annoying users with requests or other tactics. Do not disguise yourself in attempt to
                            contact someone who has blocked you. Show respect to other people's desire for space.
                        </li>
                        <li>Sharing illustrated or digitally altered pornography which depicts minors.</li>
                        <li>Sharing content that violates another person's contractual, personal, intellectual property
                            or other rights. Do not share or post links that violate the Digital Millennium Copyright
                            Act (DMCA). We respond to DMCA requests as described in our Terms of Service.
                        </li>
                        <li>Sharing content glorifying or promoting, in general, self-harm or suicide. This includes any
                            content that encourages others to: cut or injure themselves; embrace anorexia, bulimia, or
                            other eating disorders; or commit suicide rather than, for example, seeking counseling or
                            treatment.
                        </li>
                        <li>Spamming of the support or trust &amp; safety team. Do not use these channels to make false
                            reports or spam multiple reports to Terra Virtua. We are committed to addressing each item
                            as it comes in as quickly as we can.
                        </li>
                        <li>Sharing images of gore and/or animal cruelty. Do not share or post graphic images for
                            sadistic pleasure, glorification or celebration of violence.
                        </li>
                    </ul>
                    <div className="top_notice">THE FOLLOWING IS COMPLETELY UNACCEPTABLE AND WILL RESULT IN IMMEDIATE
                        CONTENT REMOVAL AND ACCOUNT DELETION:
                    </div>
                    <ul>
                        <li>Sharing content that is directly threatening someone's physical or financial state. Do not
                            threaten to harm another person or someone related to another person in any capacity. Do not
                            tell other people you will hurt or kill them. Do not dox people. Do not share or threaten to
                            share personal information of another user - this includes but is not limited to home
                            address, phone number, email, social security number, and photos. Respect other people's
                            desire for privacy.
                        </li>
                        <li>Doing anything illegal on Terra Virtua. This is directly from our Terms of Service. We will
                            take immediate action if you: violate any applicable laws or regulations, or promote or
                            encourage any illegal activity including, but not limited to, hacking, cracking or
                            distribution of pirated software, or cheats or hacks for our or another company or person's
                            Service.
                        </li>
                        <li>Sharing content related to minors that is sexually suggestive or violent. Do not share or
                            post links to images that depict minors or underage individuals engaged in violent, sexually
                            suggestive, pornographic, shameful, or otherwise inappropriate situations.
                        </li>
                        <li>Sharing or linking to content with intent to shame or degrade another individual. Do not
                            share sexually explicit content of other people without their consent. Do not share or
                            promote sharing of revenge porn.
                        </li>
                        <li>Distributing viruses. Do not engage in or promote phishing activities. Do not threaten
                            another user with digital harm including but not limited to viruses, hacking, and spying.
                        </li>
                        <li>Attempting to steal another user’s account.</li>
                    </ul>
                    <p className="text-uppercase">To report any of the above activity, please send an email to &nbsp;<a href="mailto:support@virtua.com"
                        className="link">support@virtua.com. </a>
                    </p>
                </div>
            </Container>
        </>
    );
};
